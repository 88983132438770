@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-Thin.ttf);
    // src: local('Poppins'), url('../assets/fonts/Poppins-Thin.tff') format('tff');
    font-weight: 100;
}

@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-Light.ttf);
    font-weight: 200;
}

@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: "Poppins";
    src: url(../assets/fonts/Poppins-Bold.ttf);
    font-weight: 700;
}

html {
    scroll-behavior: smooth;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

@media only screen and (min-width: 1200px) {
    .summary-card-title {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.334;
    }

    #insurance-step-1-wrapper {
        margin-top: 5%;

        .card {
            border: none;
            width: 80%;
        }

        #insurance-step-1-sale {
            text-align: left;
            padding-bottom: 15px;
            padding-top: 10px;
            font-size: 20px;
            font-weight: 500;
        }

        #insurance-step-1-title {
            padding-bottom: 15px;
            padding-top: 10px;
            font-size: 30px;
            font-weight: 700;
        }
    }

    #insurance-step-2-wrapper {
        #insurance-step-2-title {
            color: #2a4a4b;
            padding-bottom: 15px;
            padding-top: 10px;
        }
    }

    #insurance-step-3-wrapper {
        #insurance-step-3-title {
            padding-bottom: 15px;
            padding-top: 10px;
            font-size: 30px;
            font-weight: 700;
        }
    }

    #desktop-insurance-horizontal-stepper {
        display: block;

        .container-wrapper {
            width: 50% !important;
        }
    }

    #desktop-menu {
        display: flex;

        ul {
            li {
                min-width: 90px;

                a {
                    width: 110%;
                    text-align: center;
                }
            }
        }
    }

    #mobile-insurance-vertical-stepper {
        display: none;
    }

    #mobile-menu-hamburger {
        display: none;
    }
}

// @media only screen and (min-width: 992px) {
// }

@media only screen and (min-width: 768px) {
    .summary-card-title {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.334;
    }

    #insurance-step-1-wrapper {
        margin-top: 5%;

        .card {
            border: none;
            width: 80%;
        }

        #insurance-step-1-sale {
            text-align: left;
            padding-bottom: 15px;
            padding-top: 10px;
            font-size: 20px;
            font-weight: 500;
        }

        #insurance-step-1-title {
            padding-bottom: 15px;
            padding-top: 10px;
            font-size: 30px;
            font-weight: 700;
        }
    }

    #insurance-step-2-wrapper {
        #insurance-step-2-title {
            padding-bottom: 15px;
            padding-top: 10px;
            font-size: 30px;
            font-weight: 700;
        }
    }

    #insurance-step-3-wrapper {
        #insurance-step-3-title {
            padding-bottom: 15px;
            padding-top: 10px;
            font-size: 30px;
            font-weight: 700;
        }
    }

    #insurance-step-4-wrapper {
        #insurance-step-4-title {
            padding-bottom: 15px;
            padding-top: 10px;
            font-size: 30px;
            font-weight: 700;
        }
    }

    #desktop-insurance-horizontal-stepper {
        display: block;

        .container-wrapper {
            width: 75%;
        }
    }

    #desktop-menu {
        display: flex;
    }

    #mobile-insurance-vertical-stepper {
        display: none;
    }

    #mobile-menu-hamburger {
        display: none;
    }
}

// @media only screen and (min-width: 600px) {
// }

@media only screen and (max-width: 600px) {
    #insurance-step-1-wrapper {
        .card {
            border: none;
            width: 100%;

            .card-body {
                padding: 0;

                .MuiGrid-container {
                    width: 100%;

                    .MuiGrid-item {
                        display: flex;
                        justify-content: center;
                        padding: 3%;
                    }
                }
            }
        }
    }

    #insurance-step-2-wrapper {
        #insurance-step-2-title {
            font-weight: bold;
            text-align: center;
            margin-bottom: 5%;
        }
    }

    #desktop-insurance-horizontal-stepper {
        display: none;
    }

    #desktop-menu {
        display: none;
    }

    #mobile-insurance-vertical-stepper {
        display: block;
        padding: 2%;
        max-width: 100%;
    }

    #mobile-menu-hamburger {
        display: flex;
    }
}

body {
    font-family: "Poppins", sans-serif !important;
}

#footer {
    margin-top: auto;
}

.page-body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
}

.MuiStepConnector-line {
    display: none !important;
}

.input-select {
    &.Mui-error {
        .MuiSelect-select {
            border-color: #d32f2f !important;
        }
    }
}

.input-label {
    transform: translate(14px, 9px) scale(1) !important;

    &.Mui-focused {
        transform: translate(14px, -9px) scale(0.75) !important;
    }

    &.MuiFormLabel-filled {
        transform: translate(14px, -9px) scale(0.75) !important;
    }
}

.text-capitalize {
    text-transform: capitalize;
}

.text-uppercase {
    text-transform: uppercase;
}

.MuiGrid-root > .MuiGrid-item.MuiGrid-item {
    padding-top: 15px;
}

// Copyright Style One
.copyright-style-one {
    background: #FF671B;
    padding: 80px 0 50px 0;
    border-top: 1px solid var(--color-border);
    .copyright-text {
        margin-bottom: 0;
        font-size: 14px;
        color: #FFFFFF;
    }
    .ft-menu {
        padding: 0;
        margin: 0 -10px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // @media #{$sm-layout} {
        //     justify-content: center;
        // }
        li {
            margin: 0;
            padding: 0 10px;
            position: relative;
            & + li {
                &::after {
                    position: absolute;
                    content: "";
                    height: 14px;
                    width: 1px;
                    background: var(--color-border);
                    left: -1px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            a {
                font-size: 14px;
                text-decoration: none;
                color: #0A142F;
            }
        }
    }

    &.variation-two {
        background: var(--color-blackest);
    }

    &.no-border {
        border-top: 0;
    }
}

.font-bold {
    font-weight: bold !important;
}

.w-30 {
    width: 30% !important;
}

.w-35 {
    width: 35% !important;
}

.w-40 {
    width: 40% !important;
}

.w-50 {
    width: 50% !important;
}

.w-55 {
    width: 55% !important;
}

.w-60 {
    width: 60% !important;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}

.h-100vh {
    height: 100vh !important;
}

.text-align-center {
    text-align: center;
}

.pb-10 {
    padding-bottom: 10px;
}

// Hide arrow from number input
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.MuiDrawer-paper {
    width: 75%;
}

.MuiTableCell-head {
    font-weight: bold !important;
}

.font-normal {
    font-weight: normal !important;
}

.font-bold {
    font-weight: bold !important;
}

.expand-dropdown {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

#menu-products-list {
    li {
        transition: background-color 0.3s ease;

        &:hover {
            background-color: rgb(255, 227, 207);
        }
    }
}

#insurance-step-1-sale-content {
    display: inline-block;
    background: rgb(237, 247, 237);
    color: rgb(42, 74, 75);
    padding: 0.5%;
    border-radius: 4px;
    font-size: smaller;
    border: 2px solid rgb(42, 74, 75);
}

.social-button {
    box-sizing: border-box;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.26);
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    padding: 0 2px;
    border-radius: 2px;
    width: 100%;
    height: 40px;
    font-size: 0.875rem;
}

.social-icon {
    width: 36px;
    height: 36px;
    border-radius: 1px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.social-white-background {
    background-image: url(../assets/images/social-icon.png);
    width: 22px;
    height: 22px;
}

.social-white-facebook-icon {
    background-size: 325% 287.5%;
    background-position: 5.555555555555555% 62.666666666666664%;
}

.social-white-google-icon {
    background-size: 722.2222222222222% 638.8888888888889%;
    background-position: 83.92857142857143% 5.154639175257732%;
}

button {
    text-transform: capitalize !important;
}

.cursor-pointer {
    cursor: pointer;
}

// Facebook loginbutton
.kep-login-facebook {
    font-size: 0.8125rem !important;
    font-weight: 500 !important;
    line-height: 1.75 !important;
    min-width: 64px !important;
    padding: 3px 9px !important;
    border-radius: 4px !important;
    height: 100%;
    width: 100%;
}

#app-sidebar {
    li {
        a {
            color: #262626;
            font-size: 14px;
        }
    }
}

#about-us-management-team {
    .management-description {
        ul {
            list-style: none;
        }

        ul li::before {
            content: "\2022";
            color: #0f9c99;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
}

#term-and-condition {
    .body1 {
        font-size: 0.9rem;
    }
}

.swiper-button-next,
.swiper-button-prev {
    color: #009c99 !important;
}

.swiper_white_button_next,
.swiper_white_button_prev {
    color: #ffffff !important;
}

.swiper_orange_button_next,
.swiper_orange_button_prev {
    color: #ff4700 !important;
}

.swiper-pagination-horizontal {
    // background-color: rgb(237, 247, 237) !important;
    // bottom: 0 !important;
}

.swiper-pagination-bullet-active {
    background-color: #ff4700 !important;
}

.swiper-grid-column>.swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: row !important;
}

.footer-icon img {
    object-fit: contain;
}

#time_field {
    width: 100% !important;
    height: 40px;
    text-align: center;
    background: #f5f5f5;
    border: 1px solid #bbb;
    border-radius: .25rem;
}

#time_field:focus {
    border: 1px solid #1C60DD,
}

ul {
    list-style-position: outside;
    margin-bottom: 5px;
}

// waitlist
/* frame-1 styling */
.frame-1{
    font-family: "Poppins", Helvetica;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    padding-left: 15%;
    padding-right: 15%;
}
.frame-1-text{
    display: flex;
    flex-direction: column;
}
.frame-1 img{
    height: 562px;
    z-index: 1;
} 
.highlight{
    color: #FF4700;
}
  
/* button styling */
.waitlist-button{
    background-color: #FF671B;
    color: black;
    height: 45px;
    width: 197px;
    border-radius: 10px;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 500;
    margin: 3%;
    cursor: pointer;
    border: none;
}
.input-1{
    height: 45px;
    width: 339px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid black;
    outline: none;
    font-size: 20px;
}
  
/* frame-2 styling */
.frame-2{
    font-family: "Poppins", Helvetica;
    padding-top: 5%;
    background-color: #ff671b1c;
}
  
.frame-2 img{
    height: 464px;
    padding: 10px;
    margin-bottom: 3em;
}
  
.frame-2 a{
    color: black;
    font-size: 30px;
    font-weight: 400;
    text-decoration: none;
}
  
.frame-2 a:hover{
    color: #FF671B;
}
  
.student-information{
    display: flex;
    flex-direction: column;
    padding-right: 2em;
    padding-left: 2em;
    padding-bottom: 5em;
    padding-top: 1em;
    height: 110px;
    margin-left: 6%;
    border-left-style: solid;
}

.student-information.selected {
  border-left-color: orange; /* Add your desired color for the selected state */
}
  
.student-information button{
    background-color:   rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
    font-size: 30px;
    line-height: 30px;
    text-align: left;
}
  
.for-students{
    border: 1px solid black;
    width: 257px;
    height: 50px;
    border-radius: 40px;
    justify-content: center;
    margin: 5%;
    color: #ff4700;
    font-family: "Poppins", Helvetica;
    /* font-size: 25px; */
    font-weight: 500;
    text-shadow: 0px 4px 4px #ffffffb0;
    display: flex;
    align-items: center;
}
  
.img-container-frame-3{
    display: flex;
    justify-content: center;
}
  
.img-container-frame-3 img{
    height: 800px;
    width: 1280px;
    border: none;
}
  
/* demo box */
.rectangle-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #ff671b1c; */
    background-image: linear-gradient(to right ,#FFCDBD, #F3F3F300);
    padding: 25px;
    padding-bottom: 55px;
}
.rectangle-1 h1{
    font-family: "Poppins", Helvetica;
    font-weight: 600;
    /* width: 802px; */
    height: 48px;
}
.demo-form{
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    height: 153px;
    width: 696px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
  
.input-2{
    height: 45px;
    width: 339px;
    border-radius: 15px;
    text-align: center;
    /* border-color: #757575; */
    border: 1px solid #757575;
    outline: none;
    font-size: 20px;
}
  
.btn{
    background-color: #FF4700;
    border: 1px solid #00000036;
    border-radius: 15px;
    height: 45px;
    min-width: 160px;
    margin-left: 10px;
    cursor: pointer;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
}
  
.contact-button{
    text-align: left;
    height: 23px;
    width: 339px;
    cursor: pointer;
    margin-top: 15px;
}
.rectangle-1 a{
    text-decoration: none;
    color: #FF671B;
}
  
/* frame 4 */
.frame-4-employeer{
    display: flex;
    flex-direction: column;
    background-color: #ECFEF0;
    align-items: center;
    padding: 35px;
}
.frame-4-information{
    display: flex;
    flex-direction: row;
    width: 80%;
    text-align: center;
    justify-content: space-evenly;
}
.frame-4-information button {
    background-color:   rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
    font-size: 30px;
    text-align: left;
}
.frame-4-employeer a{
    justify-content: space-between;
    text-decoration: none;
    color: black;
    width: 200.573px;
    height: 45px;
    margin-top: 25px;
    font-size: 30px;
    font-weight: 400;
}
.frame-4-employeer a:hover{
    color: #FF671B;
  
}
.for-employeers{
    border: 1px solid black;
    width: 257px;
    height: 50px;
    border-radius: 40px;
    justify-content: center;
    margin-right: 51%;
    margin-top: 25px;
    color: #ff4700;
    font-family: "Poppins", Helvetica;
    font-weight: 500;
    text-shadow: 0px 4px 4px #ffffffb0;
    display: flex;
    align-items: center;
    
}
.employer-image{
    /* width: 920px; */
    height: 450px;
}
  
/* final spacing frame */
.final-frame {
    padding: 25px;
}

.input-placeholder {
    font-size: clamp(0.5rem, 3vw, 1rem); 
}

//status
.status-tag-pending {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;
    width: fit-content;
    font-size: 16px !important;
    border-radius: 4px;
    color: rgba(151, 151, 151, 1);
    background-color: rgba(151, 151, 151, 0.1);
}

.status-tag-call-for-interview {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;
    width: fit-content;
    font-size: 16px !important;
    border-radius: 4px;
    color: rgba(255, 122, 0, 1);
    background-color: rgba(255, 122, 0, 0.1);
}

.status-tag-completed {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;
    width: fit-content;
    font-size: 16px !important;
    border-radius: 4px;
    color: rgba(42, 181, 72, 1);
    background-color: rgba(42, 181, 72, 0.1);
}

.status-tag-rejected {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;
    width: fit-content;
    font-size: 16px !important;
    border-radius: 4px;
    color: rgba(255, 0, 0, 1);
    background-color: rgba(255, 0, 0, 0.1);
}

// Career
.career-first-box-description {
    padding-left: 80px;
    padding-right: 80px;
}

@media only screen and (max-width: 768px) {
    .career-first-box-description {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.career-vector-background {
    background-image: url(../assets/career_vector.png);
    background-size: 100% 100%;
    background-position-y: 125px;
    background-repeat: no-repeat;
}

.career-copyright-area-inside {
    padding-left: 20px;
    padding-right: 200px;
}

@media only screen and (max-width: 768px) {
    .career-copyright-area-inside {
        padding-left: 20px;
        padding-right: 0px;
    }
}

@media only screen and (max-width: 899px) {
    .hide-footer-menu {
        display: none;
    }
}

.status-jobtype-full-time {
    background-color: rgba(217, 249, 228, 1);
    color: rgba(20, 174, 92, 1);
}

.status-jobtype-part-time {
    background-color: rgba(255, 103, 27, 0.1);
    color: rgba(255, 103, 27, 1);
}

.status-jobtype-internship {
    background-color: rgba(0, 0, 255, 0.1);
    color: rgba(0, 0, 255, 1);
}

.status-jobtype-contract {
    background-color: rgba(255, 145, 164, 0.1);
    color: rgba(255, 145, 164, 1);
}

.status-locationtype-on-site {
    background-color: rgba(41, 151, 224, 0.1);
    color: rgba(41, 151, 224, 1);
}

.status-locationtype-hybrid {
    background-color: rgba(24, 187, 187, 0.1);
    color: rgba(24, 187, 187, 1);
}

.status-locationtype-remote {
    background-color: rgba(245, 40, 108, 0.1);
    color: rgba(245, 40, 108, 1);
}

.ql-editor:not(.view) {
    height: 200px !important;
}

.ql-toolbar.ql-snow {
    border-color: #c1c1c1;
    border-radius: 4px 4px 0px 0px;
}

.ql-container.ql-snow {
    border-color: #c1c1c1;
    border-radius: 0px 0px 4px 4px;
}